import Vue from 'vue';
import VueRouter from 'vue-router';
import pc from './pc.js';
import admin from './admin.js';

Vue.use(VueRouter);

const routes = [
  ...pc,
  ...admin
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
  // mode: 'history'
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => err);
};

const key = 'DATA_USER';
// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//     if (window.localStorage.getItem(key) || window.sessionStorage.getItem(key)) {
//       if ([ 'login', 'normal', 'loginbyverification', 'loginbywechat', 'forgetpaw', 'register' ].find((item) => item === to.name)) {
//         next(from.path);
//         return;
//       }
//       next();
//     } else {
//       if ([ 'login', 'normal', 'loginbyverification', 'loginbywechat', 'forgetpaw', 'register', 'loginBind','loginPhoneBind' ].find((item) => item === to.name)) {
//         next();
//         return;
//       }
//       next('/hint');
//     }
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  let isEnter = sessionStorage.getItem('isEnter');
  if (isEnter && isEnter === 'noEnter' && to.path !== '/login/bindPhone') {
    next('/login/bindPhone');
  } else {
    if (to.meta.requireAuth) {
      if (window.localStorage.getItem(key) || window.sessionStorage.getItem(key)) {
        if ([ 'login', 'normal', 'loginbyverification', 'loginbywechat', 'forgetpaw', 'register' ].find((item) => item === to.name)) {
          next(from.path);
          return;
        }
        next();
      } else {
        if ([ 'login', 'normal', 'loginbyverification', 'loginbywechat', 'forgetpaw', 'register', 'loginBind','loginPhoneBind', 'bindPhone' ].find((item) => item === to.name)) {
          next();
          return;
        }
        next('/hint');
      }
    } else {
      next();
    }
  }
});

export default router;
