export default {
  created () {
    this.getLocation();
  },
  methods: {
    getLocation () {
      const map = window.AMap;
      map.plugin('AMap.CitySearch', function () {
        const citySearch = new map.CitySearch();
        
        citySearch.getLocalCity(function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            const { city, province } = result;
            if (!window.sessionStorage.getItem('city')) window.sessionStorage.setItem('city', '广州市');
          }
        });
      });
    }
  }
};
