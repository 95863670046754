export default {
  namespaced: true,
  state: () => ({
    hotList: []
  }),
  mutations: {
    SET_HOT_LIST (state, payload) {
      state.hotList = payload;
    }
  },
  actions: {
  },
  getters: {}

};
