export default {
  namespaced: true,
  state: () => ({
    search: '',
    hasSearch: false,
    zhengwentongzhi: '',
    huopimingdan: '',
    contact: '',
    tongzhiAutoClick: false,
    faguiAutoClick: false,
    newsearch: '',
    tzzwShowPdf: false,
    hpmdShowPdf: false,
    preview: ''
  }),
  mutations: {
    setSearch (state, payload) {
      state.search = payload;
      state.hasSearch = !state.hasSearch;
    },
    set_tz_lx_hp (state, payload) {
      // if (payload.zhengwentongzhi) {
      //   state.zhengwentongzhi = payload.zhengwentongzhi
      // }
      // if (p ayload.huopimingdan) {
      //   state.huopimingdan = payload.huopimingdan
      // }
      // if (payload.contact) {
      //   state.contact = payload.contact
      // }
      for (const key in payload) {
        state[key] = payload[key];
      }
    },
    clickTongzhi (state, payload) {
      state.search = payload;
      state.tongzhiAutoClick = !state.tongzhiAutoClick;
    },
    clickFagui (state, payload) {
      state.search = payload;
      state.faguiAutoClick = !state.faguiAutoClick;
    },
    setNewSearch (state, text) {
      state.new_search = text;
    },
    setTZZWShowPdf (state, val) {
      state.tzzwShowPdf = val;
    },
    setHPMDShowPdf (state, val) {
      state.hpmdShowPdf = val;
    },
    SET_PREVIEW (state, val) {
      state.preview = val;
    }
  },
  actions: {
  },
  getters: {
  }
};
