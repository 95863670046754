const dict = {
  lastMatch: '/pcp/projectmatch/easy/getLastMatch', // 获取搜索框的历史记录
  matchRecord: '/pcp/projectmatch/easy/getMatchRecord', // 获取匹配记录
  matchRecordResult: '/pcp/projectmatch/getMatchRecordResult', // 获取匹配记录的项目列表
  match: '/pcp/projectmatch/easy/match', // 开始匹配
  searchResult: '/pcp/projectmatch/allentityName', // 搜索匹配
  projectPage: '/pcp/projectmatch/easy/getProjectPage', // 获取项目分页数据
  area: '/pcp/projectmatch/getArea', // 获取全国地域
  areaByCode: '/pcp/match/getChildAreaByCode', // 分开获取地域
  basicDataList: '/pcp/projectmatch/easy/getOtherBasicData', // 获取基本信息下拉列表数据
  bigCategoryList: '/pcp/projectmatch/new/getBigCategory', // 获取行业大类
  nextStep: '/pcp/projectmatch/easy/perfectOperation/nextStep', // 完善信息下一步
  prevStep: '/pcp/projectmatch/easy/perfectOperation/backStep', // 完善信息上一步
  cert: '/pcp/projectmatch/easy/searchCertification', // 搜索资质认证
  orgType: '/pcp/projectmatch/easy/searchCompanyOrgType', // 搜索企业类型
  hasPlan: '/pcp/declaremanager/addDeclarePlan', // 纳入计划
  hasDeclare: '/pcp/policyDetail/declare', // 申报
  exportResult: '/pcp/projectmatch/exportResult', // 导出
  create: '',
  edit: '',
  getMemberExclusivePhoneApi: '/customer/service/getPhone', //获取客服号码
  getQQApi: '/customer/service/getQQ', //获取客服QQ
  getWechatApi: '/customer/service/getWechat', //获取客服微信
  getWechatOfficialAccountApi: '/customer/service/getWechatOfficialAccount', //获取客服公众号
  accountInformationApi: '/pcp/PersonalCenter/accountInformation', //获取登录用户的个人信息
};

export default dict;
