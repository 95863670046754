<template>
  <div class="main">
    <div class="flex div-bottom">
      <div>广州服务贸易与服务外包公共服务平台 版权所有 粤ICP备11022414号-7 电话:020-62902130</div>
      <div>
        © CopyRight 20018-2019, gzoutsourcing.cn, Inc.All Rights Reserved.
      </div>
      <div>广州服务贸易与服务外包行业协会网站：<a style="cursor: pointer;" href="http://www.gzoutsourcing.cn/">http://www.gzoutsourcing.cn/</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerArea",
  data() {
    return {
      governmentDepartment: [
        {
          name: "广东省政府部门",
          child: [
            { name: "广东省人民政府办公厅", url: "http://www.gd.gov.cn" },
            { name: "广东省科学技术厅", url: "http://gdstc.gd.gov.cn" },
            { name: "广东省工业和信息化厅", url: "http://gdii.gd.gov.cn" },
            { name: "广东省农业农村厅", url: "http://dara.gd.gov.cn" },
            { name: "广东省商务厅", url: "http://com.gd.gov.cn" },
          ],
        },
        {
          name: "佛山市政府部门",
          child: [
            { name: "佛山市人民政府", url: "http://www.foshan.gov.cn/" },
            { name: "佛山市科技局", url: "http://fskjj.foshan.gov.cn/" },
            {
              name: "佛山市工业和信息化局",
              url: "http://fsiit.foshan.gov.cn/",
            },
            { name: "佛山市商务局", url: "http://fscom.foshan.gov.cn/" },
            { name: "佛山市农业农村局", url: "http://fsny.foshan.gov.cn/" },
          ],
        },
        {
          name: "佛山市五区政府",
          child: [
            { name: "禅城区人民政府", url: "http://www.chancheng.gov.cn/" },
            { name: "南海区人民政府", url: "http://www.nanhai.gov.cn/" },
            { name: "顺德区人民政府", url: "http://www.shunde.gov.cn/" },
            { name: "高明区人民政府", url: "http://www.gaoming.gov.cn/" },
            { name: "三水区人民政府", url: "http://www.ss.gov.cn/" },
          ],
        },
        {
          name: "其他链接",
          child: [
            {
              name: "国家科技管理信息系统公共服务平台",
              url: "https://service.most.gov.cn/index/",
            },
            {
              name: "科学技术部政务服务平台",
              url: "https://fuwu.most.gov.cn/",
            },
            {
              name: "中国及多国专利审查信息查询",
              url: "http://cpquery.cnipa.gov.cn/",
            },
            { name: "广东政务服务网", url: "http://www.gdzwfw.gov.cn/" },
            {
              name: "广东省科技业务管理阳光政务平台",
              url: "http://pro.gdstc.gd.gov.cn/egrantweb/",
            },
            // { name: '广东省科技统计综合业务管理系统', url: 'http://kj.sts.gd.cn/tl/login.aspx' },
            { name: "佛山扶持通", url: "https://fsfczj.foshan.gov.cn/" },
          ],
        },
      ],
      tabs: [
        { title: "企业概况", name: "enterprise" },
        { title: "联系我们", name: "contact" },
        { title: "常见问题", name: "problem" },
        { title: "商业合作", name: "cooperation" },
        { title: "视频教程", name: "videoTutorial" },
      ],
    };
  },
  methods: {
    jump(item) {
      if (item.name === "cooperation") {
        // this.$message.error('暂未开发此功能')
        this.$router.push({ name: "business" });
      } else if (item.name === "videoTutorial") {
        this.$router.push({ name: "videoTutorial" });
      } else {
        this.$store.commit("about/setStep", item.name);
        this.$router.push({ name: "about" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  padding: 18px 0;
  background-color: rgba(232, 236, 242, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  .div-bottom {
    flex-direction: column;
    > div {
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      a{
        color: rgba(0, 0, 0, 0.8);
      }
      a:hover{
        color: red;
      }
    }
    > div:nth-of-type(2) {
     margin: 8px 0;
    }
  }
}
</style>
