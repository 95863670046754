import {
  getToken,
  getSetToken,
  remove,
  setInfoData,
  getVip
  // getNotRead
} from '@/assets/public/utils/token';
import { request } from '../../network';
const key = 'DATA_USER';
export default {
  namespaced: true,
  state: () => ({
    user: getToken(key),
    getUserMobile: true,
    isVip: '',
    headPic: '',
    isVipShow: false
  }),
  mutations: {
    setUser (state, user) {
      remove(key, user[1]);
      state.user = user[0];

      getSetToken(key, user[0], user[1]);
      setInfoData(request, user[1]).then(mes => {
        state.getUserMobile = !state.getUserMobile;
        if (mes === 'islogin') {
          user[1] ? state.headPic = JSON.parse(window.localStorage.getItem('userinfo')).headimgurl : state.headPic = JSON.parse(window.sessionStorage.getItem('userinfo')).headimgurl;
        }
        getVip(request).then(res => {
          state.isVip = res;
        });
      });
    },
    isLoginStatus (state, type) {
      setInfoData(request, type).then(mes => {
        if (mes === 'logout') state.user = null;
        if (mes === 'islogin') {
          type ? state.headPic = JSON.parse(window.localStorage.getItem('userinfo')).headimgurl : state.headPic = JSON.parse(window.sessionStorage.getItem('userinfo')).headimgurl;
        }
        getVip(request).then(res => {
          state.isVip = res;
        });
      });
    },
    setIsVipShow (state) {
      state.isVipShow = !state.isVipShow;
    }
  },
  actions: {
  },
  getters: {
    getUser (state) {
      return state.user !== null;
    }
  }
};
