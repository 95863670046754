import { SessionUtils } from '@/utils/session';

export default {
  namespaced: true,
  state: () => ({
    componentName: SessionUtils.get('componentName') || 'Search',
    companyName: SessionUtils.get('enterpriseName') || '广东政沣云计算有限公司',
    basicData: SessionUtils.get('basicData') ||
      {"provName":null,"education":null,"provCode":null,"cityCode":null,"streetCode":null,"competent":null,"intellectualProperty":[{"tagId":"1535077923993587713","num":null,"tagName":"实用新型专利","tagRemark":null},{"tagId":"1535077924824059905","num":null,"tagName":"外观设计专利","tagRemark":null},{"tagId":"1535077925650337794","num":null,"tagName":"发明专利","tagRemark":null},{"tagId":"1535078067711414274","num":null,"tagName":"作品著作权","tagRemark":null},{"tagId":"1535078106810716161","num":null,"tagName":"软件著作权","tagRemark":null},{"tagId":"1575024972692156418","num":null,"tagName":"商标","tagRemark":null}],"certification":null,"areaCode":null,"streetName":null,"cityName":null,"areaName":null,"businessProfile":[{"tagId":"1535077739993665538","tagName":"参展参会","isChecked":null,"tagRemark":null},{"tagId":"1535077821879062529","tagName":"产学研合作","isChecked":null,"tagRemark":null},{"tagId":"1535077996957700097","tagName":"上市","isChecked":null,"tagRemark":null}],"companyOrgType":null,"enterpriseGroup":null,"category":null}, // 基本信息
    categoryTotal: SessionUtils.get('categoryTotal') || [], // 搜索的项目的选择类型
    // projectList: SessionUtils.get('projectList') || [], // 获取的项目
    stars: SessionUtils.get('stars') || 1, // 星级
    projectNum: SessionUtils.get('projectNum') || 32,
    recordId: SessionUtils.get('recordId') || null,
    tagGroupLists: SessionUtils.get('tagGroupLists') || [], // 全部的标签数据
    curTagPage: SessionUtils.get('curTagPage') || 0, // 标签选择当前步数
    progressBar: SessionUtils.get('progressBar') || [],
    // tagGroupLists: [], // 全部的标签数据
    // curTagPage: 0, // 标签选择当前步数
    // progressBar: [],
    baseURL: process.env.VUE_APP_BASEURL
  }),
  mutations: {
    setStars (state, payload) {
      state.stars = payload;
      SessionUtils.set('stars', payload);
    },
    setComponentName (state, payload) {
      state.componentName = payload;
      SessionUtils.set('componentName', payload);
    },
    setCompanyName (state, payload) {
      state.companyName = payload;
      SessionUtils.set('enterpriseName', payload);
    },
    setBasicData (state, payload) {
      state.basicData = payload;
      SessionUtils.set('basicData', payload);
    },
    setCategoryTotal (state, payload) {
      state.categoryTotal = payload;
      SessionUtils.set('categoryTotal', payload);
    },
    setProjectList (state, payload) {
      state.projectList = payload;
    },
    setProjectNum (state, payload) {
      state.projectNum = payload;
    },
    setBasicDatas (state, payload) {
      state.basicData = payload.basicData;
      state.categoryTotal = payload.categoryTotal;
      state.recordId = payload.recordId;
      SessionUtils.set('recordId', payload.recordId);
      SessionUtils.set('basicData', payload.basicData);
      SessionUtils.set('categoryTotal', payload.categoryTotal);
      // SessionUtils.set('projectList', payload.projectList)
    },
    pushProgressBar (state, payload) {
      state.progressBar.push(payload);
      SessionUtils.set('progressBar', state.progressBar);
    },
    popProgressBar (state, payload) {
      state.progressBar.pop();
      SessionUtils.set('progressBar', state.progressBar);
    },
    pushTagGroupLists (state, payload) {
      state.tagGroupLists.push(payload);
      state.curTagPage += 1;
      SessionUtils.set('curTagPage', state.curTagPage);
      SessionUtils.set('tagGroupLists', state.tagGroupLists);
    },
    popTagGroupLists (state, payload) {
      state.tagGroupLists.pop();
      state.curTagPage -= 1;
      SessionUtils.set('curTagPage', state.curTagPage);
      SessionUtils.set('tagGroupLists', state.tagGroupLists);
    },
    resetTagGroupLists (state) {
      state.tagGroupLists = [];
      // state.curTagPage = 0
      // state.progressBar = []
      // SessionUtils.set('curTagPage', state.curTagPage)
      SessionUtils.set('tagGroupLists', state.tagGroupLists);
    },
    resetProCurTag (state) {
      state.curTagPage = 0;
      state.progressBar = [];
      SessionUtils.set('curTagPage', state.curTagPage);
      SessionUtils.set('progressBar', state.progressBar);
    },
    setData (state, payload) {}
  },
  actions: {
  },
  getters: {
    getBasicData () {}
  }
};
