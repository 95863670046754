import { setInfoData } from '@/assets/public/utils/token.js';
export default {
  methods: {
    mixingetInfoData (request) {
      setInfoData(request);
    },
    mixinGetLogin () {
      if (window.localStorage.getItem('DATA_USER') || window.sessionStorage.getItem('DATA_USER')) {
        return true;
      } else {
        return false;
      }
    }
  }
};
