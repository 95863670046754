/*
 * @Author: zhangminxiang
 * @Descripttion:
 * @version: 1.0
 * @Date: 2022-03-08 11:50:25
 * @LastEditors: zhangminxiang
 * @LastEditTime: 2022-03-11 16:45:48
 */
export default {
  namespaced: true,
  state: () => ({
    stepNum: 0,
    result: ''
  }),
  mutations: {
    setStepNum (state) {
      state.stepNum++;
    },
    changeStepNum (state, data) {
      const status = data === 4 ? 3 : data;
      if (data === 4) state.result = 'fail';
      if (data === 3) state.result = 'success';
      state.stepNum = status;
    },
    getSessionData (state, data) {
      state.stepNum = data.stepNum;
      state.result = data.result;
      window.sessionStorage.removeItem('agencies');
    }
  },
  actions: {
  },
  getters: {}
};
